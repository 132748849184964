import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useUIContext } from '../context';
import { apiCall } from '../services/authServieces';
import Plus from "../Assets/images/plus.png";
import '../css/createbot.css';
import DeleteIcon from "../Assets/images/delete.png";
import EditIcon from "../Assets/images/edit.png";
import { ClipLoader } from "react-spinners";
import { SettingsApplicationsOutlined } from '@mui/icons-material';

const CreateBot = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to control the visibility of the modal
  const [botName, setBotName] = useState(''); // State to store the current bot name
  const [bots, setBots] = useState([]); // State to store the list of bots
  const { setBotID } = useUIContext();
  const [businessNo, setBusinessNo] = useState([]);
  const [selectOption, setSelectedOption] = useState('');

  // Fetch bots data when the component mounts
  useEffect(() => {
    fetchBots();
    fetchBusinessNo(); // Fetch business numbers as well
  }, []);

  // Function to fetch bots from the backend
  const fetchBots = async () => {
    try {
      setLoading(true);
      const response = await apiCall({ endpoint: 'api/getBotData', method: 'get' });
      // console.log(response);
      setBots(response || []);
    } catch (error) {
      console.error('Error fetching bots:', error);
    }finally{
      setLoading(false);
    }
  };

  // Function to fetch business numbers from the backend
  const fetchBusinessNo = async () => {
    try {
      setLoading(true);
      const response = await apiCall({ endpoint: 'api/get-business-no', method: 'get' });
      setBusinessNo(response.data);
    } catch (error) {
      console.error('Error fetching business numbers:', error);
    }finally{
      setLoading(false);
    }
  };

  // Function to handle button click to show modal
  const handleButtonClick = () => {
    setShowModal(true);
  };

  // Function to handle input change
  const handleInputChange = (event) => {
    setBotName(event.target.value); // Update bot name state on input change
  };

  // Function to handle save button click
  const handleSaveClick = async () => {

    if(selectOption.trim()===''){

      return alert('Please select business no')
    }

    if(botName.trim()===''){

      return alert('Please Enter bot name ')
    }

    setLoading(true);

    if (botName.trim() !== '') {
      try {
        let payload = { name: botName, business_no: selectOption };
        await apiCall({ endpoint: 'api/bots', method: 'post', payload: payload });
        setBotName(''); // Clear the input field
        setShowModal(false); // Hide the modal
        fetchBots(); // Refresh the list of bots after adding a new one
        alert('Bot created successfully!'); // Show success message
      } catch (error) {
        console.error('Error saving bot:', error);
        alert('Error saving bot. Please try again.'); // Show error message
      }finally{
        setLoading(false);
      }
    }
  };

  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const deleteBot = async (id,bot_id) => {

    let payload = { id: id ,bot_id:bot_id };
    const data = await apiCall({ endpoint: 'api/delete-bot', method: 'post', payload: payload });

    if (data.status === 200) {
      alert(data.message)
    }
    fetchBots();

  }

   // Function to handle "Edit" action
  //  const handleEdit = (Id) => {
  //   alert(`Edit action triggered for bot ID: ${Id}`);
  // };

  // Function to handle "Active" action
  const handleActivate = async (id, business_no) => {
    try {
   
      let payload = { id: id, business_no: business_no };
      const data = await apiCall({ endpoint: 'api/change-bot-status', method: 'post', payload: payload });
      if (data.status === 200) {
        alert(data.message);
        fetchBots();
      }
    } catch (error) {
      console.error('Error updating bot status:', error);

    }

  };

  // Function to handle "Deactive" action
  const handleDeactivate = async (Id) => {
    let payload = { id: Id  };
    const data = await apiCall({ endpoint: 'api/deactive-bot-status', method: 'post', payload: payload });

    if (data.status === 200) {
      alert(data.message)
    }
    fetchBots();
  };

  // Function to handle status toggle
  // const handleStatusToggle = async (id, business_no) => {
  //   try {
   
  //     let payload = { id: id, business_no: business_no };
  //     const data = await apiCall({ endpoint: 'api/change-bot-status', method: 'post', payload: payload });
  //     if (data.status === 200) {
  //       alert(data.message);
  //       fetchBots();
  //     }
  //   } catch (error) {
  //     console.error('Error updating bot status:', error);

  //   }

  // };

  return (
    <>
    <div  className="Template_id_contian2">
        <h4 className="Head_title2">Create Bot
    <button id='create-bot-button' className=' CreateBotAddBtn' onClick={handleButtonClick} data-bs-toggle="modal"
    data-bs-target="#Preview_modal">
        <img src={Plus} alt="img" /> Create Bot
      </button>
  </h4>


  

      <div className=" table_contain mt-4">
        <table className='table table-bordered Table_bot Table'>
          <thead>
            <tr>
              <th>Bot Name</th>
              <th>Business No</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {bots.length > 0 ? (
              bots.map((bot, index) => (
                <tr key={index}>
                  <td>{bot.bot_name}</td>
                  <td>{bot.business_no}</td>
                  <td>{bot.status}</td>
                  <td>
                    <div className='d-flex justify-content-center'>
                      
                    <NavLink
                      to="/chatboat"
                      onClick={() => {
                        const botData = { botId: bot.bot_id, userId: bot.user_id, businessNo: bot.business_no, admin_id: bot.admin_id, botName: bot.bot_name, created_date: bot.c_date };
                        localStorage.setItem('botData', JSON.stringify(botData)); // Set bot data in localStorage
                        setBotID(botData); // Set the bot ID in the context
                      }}
                    >
                      <button className="btn btn-primary btn-sm download_icon mt-0"><img src={EditIcon} alt="icon" /></button>
                    </NavLink>


                                  
               
<select 
  className="form-select w-50 CreatBotSelection" 
  aria-label="Select action"
  onChange={(e) => {
    const selectedAction = e.target.value;
    if (selectedAction === "activate") {
      handleActivate(bot.id, bot.business_no);
    } else if (selectedAction === "deactivate") {
      handleDeactivate(bot.id);
    }
  }}
>

  <option value="" disabled selected>Select action</option>
  {/* <option value="edit">Edit</option> */}
  <option value="activate">Active</option>
  <option value="deactivate">Deactive</option>
</select>


<button className="btn btn-danger btn-sm  mt-0"
                      onClick={() => deleteBot(bot.id,bot.bot_id)}
                    >
                      <img src={DeleteIcon} alt="icon" />
                    </button>

                    </div>
            
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">No data found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
    <div
  className="modal fade"
  id="Preview_modal"
  tabindex="-1"
  aria-labelledby="Preview_modal_Label"
  aria-hidden="true"
>
  <div className="modal-dialog maxW400">
      <div className="modal-header">
        <h5 className="modal-title w-100 text-center" id="Preview_modal_Label">Create Bot</h5>
      </div>
    <div className="modal-content">
      <div className="modal-body p-0">
        <div className="Modal_Table_contain">
          <div className="FormGroup">
            <label htmlFor="type">Select Business No<span className="required_icon">*</span></label>
            <select
              className='form-select-lg w-100'
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              <option>---Select---</option>
              {businessNo.map((option, index) => (
                <option key={index} value={option.business_no}>
                  {option.business_no}
                </option>
              ))}
            </select>
          </div>
          <div className="FormGroup">
            <label htmlFor="file">Bot Name <span className="required_icon">*</span></label>
            <input
  type="text"
  className='modal_input_bot'
  placeholder="Ex. Marketing Bot"
  value={botName}
  onChange={handleInputChange}
/>
          </div>
          <div className="modal-buttons">
            <button type='button' className='Modal_save_btn' onClick={handleSaveClick}>Save</button>
             <button type="button" className="Modal_cancel_btn ms-2" 
          data-bs-dismiss="modal"
          aria-label="Close">Cancel</button>
            </div>
    </div>
      </div>
    </div>
  </div>
</div>
{loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}
    </>
  );
};

export default CreateBot;
