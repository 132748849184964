import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./sidebar.css";
import List from "../../Assets/images/list.png";
import CloseIcon from "../../Assets/images/close_icon.png";
import Dash from "../../Assets/images/dashboard.png";
import Manage from "../../Assets/images/manage.png";
import transactionIcon from "../../Assets/images/transaction.png";
import { useUIContext } from "../../context/index";
import Logo from "../../Assets/images/logo.png";
import Report from "../../Assets/images/report.png";

const AdminSidebar = () => {
  const location = useLocation();
  const { isSidebarCollapsed, setIsSidebarCollapsed, userType } =
    useUIContext();

  const isActivePath = (path) => location.pathname === path;

  return (
    <div className="Sidebar" id="Sidebar">
      <div className="Sidebar_head">
        {/* <NavLink to="#"><img src={SmsIcon} alt="img" /></NavLink> */}
        <NavLink to="#">
          <img src={Logo} alt="img" />
        </NavLink>
        <NavLink to="#">EASYGOWP</NavLink>
        <button
          id="Menu_close"
          onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        >
          <img src={CloseIcon} alt="img" />
        </button>
      </div>
      <div className="Sidebar_contain">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <NavLink to="/">
                <button
                  className={`accordion-button bg_none collapsed ${
                    isActivePath("/") ? "active" : ""
                  }`}
                  type="button"
                >
                  <img src={Dash} alt="img" /> <span>Dashboard</span>
                </button>
              </NavLink>
            </h2>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button collapsed ${
                  isActivePath("/admin/manage-dealer") ? "active" : ""
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Dropdown_3"
                aria-expanded="false"
                aria-controls="Dropdown_3"
              >
                <img src={Manage} alt="img" /> <span>Manage</span>
              </button>
            </h2>
            <div
              id="Dropdown_3"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <NavLink
                  to="/admin/manage-dealer"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={List} alt="img" />
                  Manage User
                </NavLink>
             
         
                <NavLink
                  to="/admin/manage-oem"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={List} alt="img" />
                  Manage Oem
                </NavLink>
                <NavLink
                  to="/admin/template-oem"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={List} alt="img" />
                  Template Oem
                </NavLink>

                </div>
             
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button collapsed ${
                  isActivePath("/admin/transaction-details") ? "active" : ""
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Dropdown_2"
                aria-expanded="false"
                aria-controls="Dropdown_2"
              >
                <img src={transactionIcon} alt="img" /> <span>Transaction</span>
              </button>
            </h2>
            <div
              id="Dropdown_2"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <NavLink
                  to="/admin/transaction-details"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={List} alt="img" />
                  Wallet
                </NavLink>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button collapsed ${
                  isActivePath("/admin/delivery-report") ? "active" : ""
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Dropdown_4"
                aria-expanded="false"
                aria-controls="Dropdown_4"
              >
                <img src={Report} alt="img" /> <span>Reports</span>
              </button>
            </h2>
            <div
              id="Dropdown_4"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <NavLink
                  to="/admin/delivery-report"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={List} alt="img" />
                  Live Report
                </NavLink>
                <NavLink
                  to="/admin/mis-report"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={List} alt="img" />
                  Mis Report
                </NavLink>
                {userType === "super_admin" && (
                  <NavLink
                    to="/admin/updated-report"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    <img src={List} alt="img" />
                    Updated Report
                  </NavLink>
                )}
                <NavLink
                  to="/admin/wallet-alert"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={List} alt="img" />
                  Wallet Alert
                </NavLink>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button collapsed ${
                  isActivePath("/admin/pending-report") ? "active" : ""
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Dropdown_5"
                aria-expanded="false"
                aria-controls="Dropdown_5"
              >
                <img src={Report} alt="img" /> <span>Pending Messages</span>
              </button>
            </h2>
            <div
              id="Dropdown_5"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <NavLink
                  to="/admin/pending-report"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <img src={List} alt="img" />
                  Live Pending Msg
                </NavLink>
              </div>
            </div>
          </div>
          {/* Add more admin-specific sidebar items */}
        </div>
      </div>
      <div className="sidebar_bg_img"></div>
    </div>
  );
};

export default AdminSidebar;
