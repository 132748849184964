import React, { useState, useEffect, useCallback } from "react";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import { apiCall } from "../../services/authServieces";
import ShowSnackBar from "../../components/snackBar";
import SearchIcon from "@mui/icons-material/Search";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useUIContext } from "../../context";
import Table from "@mui/material/Table";
import "../../css/manage_waba.css";
import "../../css/wb_report_details.css";
import { formatDate } from "../../utils/helper";
import { Navigate } from "react-router-dom";
import CustomDateRangePicker from "../../components/CustomDatePicker";
import Search from "../../Assets/images/search.png";
import { formatDatePickerDate } from "../../utils/helper";
import { ClipLoader } from "react-spinners";
// import { date } from "yup";

const DeliveryReportDetail = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [redirectToAdmin, setRedirectToAdmin] = useState(false);

  const columns = [
    { id: "date", label: "date", minWidth: 100 },
    { id: "user_id", label: "User Id", minWidth: 100 },
    { id: "user_name", label: "User name", minWidth: 100 },
    { id: "actual_delivered", label: "Actual Delivered", minWidth: 100 },
    { id: "actual_failed", label: "Actual Failed", minWidth: 100 },
    { id: "modified_status", label: "Modified Status", minWidth: 100 },
  ];
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
    const [selectedRange, setSelectedRange] = useState({
      startDate: '',
      endDate: ''
    })
    const { dateRanges } = useUIContext();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseSnackBar = (event, reason) => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      let endpoint = `dashboard/modified-report?page=${page}&limit=${rowsPerPage}`;
      if (debouncedSearchTerm) {
        endpoint = `${endpoint}&search=${debouncedSearchTerm}`;
      }
        if(selectedRange.startDate && selectedRange.endDate){
          endpoint = `${endpoint}&startDate=${selectedRange.startDate}&endDate=${selectedRange.endDate}`
        }
      const response = await apiCall({ method: "GET", endpoint: endpoint });
      if (response.success) {
        const data = response.data;
        if (data && data.length) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            const formattedDate = formatDate(element.date);
            element["date"] = `${formattedDate}`;
          }
        }
        setRows(response.data);
        setTotal(response.total);
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: false,
        message:
          error?.response?.data?.message ||
          "Server Error. Please try again later.",
      });
      setTimeout(() => {
        if(error?.response?.status === 403){
          setRedirectToAdmin(true);
        }
      }, 2000);
     
    } finally{
      setLoading(false);
    }
  }, [page, rowsPerPage, debouncedSearchTerm, selectedRange]);


  

    const handleDateClick = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
          const dates = formatDatePickerDate(dateRanges, "modifiedReport")
          if(dates && dates.length){
            console.log(!(dates[0] === "1970-01-01" && dates[1] === "1970-01-01"))
              if(!(dates[0] === "1970-01-01" && dates[1] === "1970-01-01")){
                console.log('dates[1]: ', dates[1]);
                console.log('dates[0]: ', dates[0]);
                  setSelectedRange({
                      startDate: dates[0],
                      endDate: dates[1],
                  });
              }else{
                setSelectedRange({
                  startDate: null,
                  endDate: null,
              });
              }
          }
      } catch (error) {
        console.log(error);
      } finally{
        setLoading(false);
      }
    };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearchChange = (e) => {
    console.log("e.target.value: ", e.target.value);
    setSearchTerm(e.target.value);
  };

  if (redirectToAdmin) {
    return <Navigate to="/admin" replace />;
  }

  return (
    <>
      <div className="Template_id_contian">
        <h4 className="Head_title  ReportDetails">
          <div className="DateRangeContain">
            <CustomDateRangePicker rangeKey="modifiedReport" />
            <button
              type="button"
              className="SearchDateRange"
              onClick={handleDateClick}
            >
              <img src={Search} alt="Search" />
            </button>
          </div>
          Modified Report
          <div className="Session_report_SearchContain">
            <input
              placeholder="Search"
              type="text"
              className=""
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="button">
              <SearchIcon />
            </button>
          </div>
        </h4>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="Table DeliveryReapotTable"
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    // onClick={() => handleRowClick(row)}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return <TableCell key={column.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}
      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default DeliveryReportDetail;
