import React, { useState, useEffect, useCallback } from "react";
import TablePagination from "@mui/material/TablePagination";
import { useLocation } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import { apiCall } from "../../services/authServieces";
import ShowSnackBar from "../../components/snackBar";
import SearchIcon from "@mui/icons-material/Search";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import "../../css/manage_waba.css";
import "../../css/wb_report_details.css";
import { formatDate } from "../../utils/helper";
import { ClipLoader } from "react-spinners";


const DeliveryReportDetail = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const location = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rowData = location.state?.rowData;
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const columns = [
    { id: "send_date", label: "Date", minWidth: 100 },
    { id: "sender_mobile", label: "Mobile", minWidth: 100 },
    { id: "msg", label: "Message", minWidth: 100 },
    { id: "temp_category", label: "Category", minWidth: 100 },
    { id: "msg_type", label: "Type", minWidth: 100 },
    { id: "delivery_status", label: "status", minWidth: 100 },
  ];

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseSnackBar = (event, reason) => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if(rowData?.created_by){
          let endpoint = `dashboard/get-delivery-details/${rowData?.created_by}?page=${page}&limit=${rowsPerPage}`;
          if (debouncedSearchTerm) {
            endpoint = `${endpoint}&search=${debouncedSearchTerm}`;
            console.log("endpoint: ", endpoint);
          }
          const response = await apiCall({ method: "GET", endpoint: endpoint });
          if (response.success) {
            const data = response.data
            if(data && data.length){
              for (let i = 0; i < data.length; i++) {
                const element = data[i];
                const formattedDate = formatDate(element.send_date)
                element['send_date'] = `${formattedDate} ${element.send_time}`
              }
            }
            setRows(response.data);
            setTotal(response.total);
          }
      }
    } catch (error) {
      console.error(error);
    } finally{
      setLoading(false);
    }
  }, [page, rowsPerPage,rowData?.created_by, debouncedSearchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <div className="Template_id_contian">
        
        <h4 className="Head_title  ReportDetails AdminSearchContain DailyReport">
        <span className="Font14">{rowData?.user_name}</span>
          Live Report
          <div className="Session_report_SearchContain">
            <input
              placeholder="Search"
              type="text"
              className=""
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="button">
              <SearchIcon />
            </button>
          </div>
        </h4>
        
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" className="Table  DeliveryReapotTable">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    // onClick={() => handleRowClick(row)}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return <TableCell key={column.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}
      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default DeliveryReportDetail;
