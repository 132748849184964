import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/footer';
import WbQuickCampaigns from './pages/WbQuickCampaigns';
import WbCustomizeCampaigns from './pages/WbCustomizeCampaigns';
import WbTemplate from './pages/WbTemplate';
import WbManageMedia from './pages/WbManageMedia';
import WbManageGroups from './pages/WbManageGroups';
import WbManageBlockNumber from './pages/WbManageBlockNumber';
import WbAddTemplate from './pages/WbAddTemplate';
import WbManageDealer from './pages/admin/WbManageDealer';
import WbDevelopersTools from './pages/WbDevelopersTools';
import WbTransactionDetail from './pages/admin/WbTransactionDetail';
import WbDeliveryReport from './pages/WbDeliveryReport';
import WbSessionRecievedReport from './pages/WbSessionRecievedReport';
import WbMisReport from './pages/WbMisReport';
import WbDealerwiseMisReport from './pages/WbDealerwiseMisReport';
import Dashboard from './pages/Dashboard';
import AdminDashboard from './pages/admin/adminDashboard';
import AdminGuard from './guards/AdminGuard';
// import Drag from './pages/Drag'
import WbLoginDetails from './pages/WbLoginDetails';
import Demo from './pages/Demo';
import Login from './pages/Login';
import { useUIContext } from './context';
import AuthGuard from './guards/authGuards';
import { Me } from './services/authServieces';

import CreateFirstPage from './pages/CreateFirstPage';
import AdminPanel from './pages/mainquestion';
import Chatboat from './pages/Chatboat';
import CreateBot from './pages/CreateBot';
import Managebot from './pages/Managebot';

import Profile from './pages/profile';
import Changepassword from './pages/Changepassword';
import ManageWaba from './pages/admin/ManageWaba'

import EditGroupNumbers from './pages/EditGroupNumbers';

import DeliveryReport from './pages/admin/DeliveryReport';
import DeliveryReportDetail from './pages/admin/ReportDetails';
import TemplateReportDetail from './pages/admin/TemplateReportDetail';
import MisReport from './pages/admin/MisReport';
import MisReportDetail from './pages/admin/MisReportDetails';
import WalletAlert from './pages/admin/WalletAlert';
import ModifiedReport from './pages/admin/ModifiedReport';
import PendingReport from './pages/admin/PendingMessageAdmin';
import NotFound from './components/NotFound';
import CampaignWiseReport from './pages/CampaignWisereport';
import WpUserTransactionDetails from './pages/WpUsertransactionDetails';



// OEM START

import WbManageOEM from './pages/admin/WpManageOEM'; // this is at admin section

import OemAdminDashboard from './pages/OEM/oemDashboard';
import OemDeliveryReport from './pages/OEM/oemDeliveryReport';
import OEMMisReport from './pages/OEM/OemMISReport';
import OEMWalletAlert from './pages/OEM/WalletData';
import TemplateWiseReport from './pages/OEM/OemTemplateWisedata';
import WbTemplateOEM from './pages/admin/WbTemplateOEM';

import OemDetailedDeliveryReport from './pages/OEM/oemDetailedDeliveryReport'; //oem detailed report ...

import MisOemDetailedReport from './pages/OEM/OemMisdetailedReport'; // MIS Detailed Report for OEM 

import OemTemplateDetailedReport from './pages/OEM/OemTemplateWiseDetailedReport';

// OEM END


const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Move useNavigate here
  const showNavbarFooter = !['/login','/managebot','/notfound'].includes(location.pathname);
  const { isSidebarCollapsed,userType ,setUserType} = useUIContext();




  const excludedPaths = ['/login', '/managebot','/notfound'];
  const isLoginPage = excludedPaths.includes(location.pathname);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await Me();
        const userType = userData?.data?.user_type
        setUserType(userType); 
        if (location.pathname === '/' && (userType === 'admin' || userType === 'super_admin')) {
          navigate('/admin'); 
        }
        if (location.pathname === '/' && (userType === 'OEM' || userType === 'OEM')) {
          navigate('/oem-dashboard'); 
        }
      } catch (error) {
        navigate('/login'); 
      }
    };

    fetchData();
  }, [navigate]); // Empty dependency array ensures this runs on every component mount

  return (
    <div id="page-body" className={!isSidebarCollapsed ? 'sidebar-collapsed' : ''}>
    <section className={isLoginPage ? '' : 'Contain'} id="Contain">
    {showNavbarFooter && <Header isSidebarCollapsed={isSidebarCollapsed} />}
      <div  className={isLoginPage ? '' : 'Dash_contain'}>
          <Routes>
            {/* Protected Routes */}
            {/* <Route path="/drag" element={<Drag />} /> */}
            <Route path="/" element={<AuthGuard><Dashboard /></AuthGuard>} />
            <Route path="/wbquickcampaigns" element={<AuthGuard><WbQuickCampaigns /></AuthGuard>} />
            <Route path="/wbcustomizecampaigns" element={<AuthGuard><WbCustomizeCampaigns /></AuthGuard>} />
            <Route path="/wbtemplate" element={<AuthGuard><WbTemplate /></AuthGuard>} />
            <Route path="/wbmanagemedia" element={<AuthGuard><WbManageMedia /></AuthGuard>} />
            <Route path="/wbmanagegroups" element={<AuthGuard><WbManageGroups /></AuthGuard>} />
            <Route path="/wbmanageblocknumber" element={<AuthGuard><WbManageBlockNumber /></AuthGuard>} />
            <Route path="/wbaddtemplate" element={<AuthGuard><WbAddTemplate /></AuthGuard>} />
            <Route path="/wbdeveloperstools" element={<AuthGuard><WbDevelopersTools /></AuthGuard>} />
            <Route path="/wbdeliveryreport" element={<AuthGuard><WbDeliveryReport /></AuthGuard>} />
            <Route path="/wbsessionrecievedreport" element={<AuthGuard><WbSessionRecievedReport /></AuthGuard>} />
            <Route path="/wbmisreport" element={<AuthGuard><WbMisReport /></AuthGuard>} />
            <Route path="/wbdealerwisemisreport" element={<AuthGuard><WbDealerwiseMisReport /></AuthGuard>} />
            <Route path="/wblogindetails" element={<AuthGuard><WbLoginDetails /></AuthGuard>} />
            <Route path="/demo" element={<AuthGuard><Demo /></AuthGuard>} />
            <Route path="/edit-group" element={<AuthGuard><EditGroupNumbers /></AuthGuard>} />


            <Route path="/userwalletdetails" element={<AuthGuard><WpUserTransactionDetails /></AuthGuard>} />

            <Route path="/campaignwise_report" element={<AuthGuard><CampaignWiseReport /></AuthGuard>} />


            {/* Admin Routes*/}
            <Route path="/admin" element={<AuthGuard><AdminGuard><AdminDashboard /></AdminGuard></AuthGuard>} />
            <Route path="/admin/transaction-details" element={<AuthGuard><AdminGuard><WbTransactionDetail /></AdminGuard></AuthGuard>} />
            <Route path="/admin/manage-dealer" element={<AuthGuard><AdminGuard><WbManageDealer /></AdminGuard></AuthGuard>} />
            <Route path="/admin/manage-waba" element={<AuthGuard><AdminGuard><ManageWaba/></AdminGuard></AuthGuard>} />
            <Route path="/admin/delivery-report" element={<AuthGuard><AdminGuard><DeliveryReport/></AdminGuard></AuthGuard>} />
            <Route path="/admin/delivery-report/:userId" element={<AuthGuard><AdminGuard><DeliveryReportDetail/></AdminGuard></AuthGuard>} />
            <Route path="/admin/template-report/:userId" element={<AuthGuard><AdminGuard><TemplateReportDetail/></AdminGuard></AuthGuard>} />
            <Route path="/admin/mis-report" element={<AuthGuard><AdminGuard><MisReport/></AdminGuard></AuthGuard>} />
            <Route path="/admin/mis-report/:userId" element={<AuthGuard><AdminGuard><MisReportDetail/></AdminGuard></AuthGuard>} />
            <Route path="/admin/wallet-alert" element={<AuthGuard><AdminGuard><WalletAlert /></AdminGuard></AuthGuard>} />
            <Route path="/admin/updated-report" element={<AuthGuard><AdminGuard><ModifiedReport /></AdminGuard></AuthGuard>} />
            {/* <Route path="/admin/manage-waba" element={<AuthGuard></AuthGuard>} /> */}


            <Route path="/admin/pending-report" element={<AuthGuard><AdminGuard><PendingReport/></AdminGuard></AuthGuard>} />

            <Route path="/admin/manage-oem" element={<AuthGuard><AdminGuard><WbManageOEM/></AdminGuard></AuthGuard>} />
            <Route path="/admin/template-oem" element={<AuthGuard><AdminGuard><WbTemplateOEM  /></AdminGuard></AuthGuard>} />

            <Route path="/createbot" element={<CreateBot/>} />
            <Route path="/createfirstPage" element={<CreateFirstPage />} />
            <Route path="/mainquestion" element={<AdminPanel />} />
            <Route path="/chatboat" element={<Chatboat />} />
            <Route path="/managebot" element={<Managebot />} />

            <Route path="/profile" element={<AuthGuard><Profile/></AuthGuard>} />
            <Route path="/changepassword" element={<AuthGuard><Changepassword/></AuthGuard>} />


            {/* OEM dashboard */}

            <Route path="/oem-dashboard" element={<AuthGuard><OemAdminDashboard /></AuthGuard>} />


            <Route path="/oem/delivery-report" element={<AuthGuard><OemDeliveryReport /></AuthGuard>} />
            <Route path="/oem/delivery-report/:userId" element={<AuthGuard><OemDetailedDeliveryReport /></AuthGuard>} />


            <Route path="/oem/mis-report" element={<AuthGuard><OEMMisReport /></AuthGuard>} />
            <Route path="/oem/mis-report/:userId" element={<AuthGuard><MisOemDetailedReport /></AuthGuard>} />

            <Route path="/oem/wallet-data" element={<AuthGuard><OEMWalletAlert /></AuthGuard>} />

            <Route path="/oem/get-templatewisedata" element={<AuthGuard><TemplateWiseReport /></AuthGuard>} />

            <Route path='/oem/get-templatewisedata/:userId' element={<AuthGuard><OemTemplateDetailedReport/></AuthGuard>}/>
            {/* OEM data */}
            
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/notfound" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/notfound" />} />



          </Routes>
        </div>
      </section>
      {showNavbarFooter && <Footer />}
    </div>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
};

export default App;
