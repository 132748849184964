import React, { useEffect, useState } from 'react';
import '../css/wb_manage_media.css';
import Eye from '../Assets/images/eye.png';
import Delete from '../Assets/images/delete.png';
import Plus from '../Assets/images/plus.png';
import Copy from '../Assets/images/copy.png';
import { Link } from 'react-router-dom';
import { apiCall } from '../services/authServieces';
import { ClipLoader } from "react-spinners";

const WbManageMedia = () => {
  const [loading, setLoading] = useState(false);
  const [mediaTitle, setMediaTitle] = useState('');
  const [mediaType, setMediaType] = useState('');
  const [file, setFile] = useState(null);
  const [tabledata, setTableData] = useState([]);
  const [deleteModalId, setDeleteModalId] = useState(null);
  const [fileType,setFileType]=useState('')

  useEffect(() => {
    getMediaData();

  }, []);

  const getMediaData = async () => {
    setLoading(true);
    try {
      let responseData = await apiCall({ endpoint: 'api/getmediaData', method: 'GET' });
      if (responseData.success) {
        const mediaData = responseData.data.map(item => ({
          cdate: item.cdate,
          ctime: item.ctime,
          media_title: item.media_title,
          media_type: item.media_type,
          media_url: item.media_url,
          id: item.id
        }));
        setTableData(mediaData);
      } else {
        setTableData([]);
      }
    } catch (err) {
      console.log('Error in fetching Media data', err);
      setTableData([]);
    } finally{
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (mediaTitle.trim() === '') {
        return alert('Please Enter Media Title');
      }
      if (mediaType.trim() === '') {
        return alert('Please Enter Media Type');
      }
      if (!file) {
        return alert('Please Upload file first');
      }

      const formData = new FormData();
      formData.append('mediaTitle', mediaTitle);
      formData.append('mediaType', mediaType);
      formData.append('file', file);

      let responseData = await apiCall({ endpoint: 'api/savemanagemedia', method: 'POST', payload: formData });
      if (responseData.success) {
        alert('Data is Saved');
        window.location.reload();
      } else {
        alert('Please try again');
      }
    } catch (err) {
      console.log('Error in Save Data', err);
    } finally{
      setLoading(false);
    }
  };

  const handleSaveFile = (e) => {
    setLoading(true);
    try {
      let data = e.target.files[0];
      if (data) {
        // Check if the file size is less than 2 MB
        if (data.size < 2 * 1024 * 1024) {
          setFile(data);
        } else {
          alert("File size should be less than 2 MB.");
          e.target.value = null; // Clear the file input
        }
      }
    } catch (err) {
      console.log('Error in save file', err);
    } finally{
      setLoading(false);
    }
  };

  const DeleteFunction = async (deleteId) => {
  
    if (!deleteId) {
      return alert(' Please try again.');
    }
    setLoading(true);
    try {
      const payloadData = {
        id: deleteId
      };
  
      const responseData = await apiCall({ endpoint: `api/deletemediaData`, method: 'POST', payload: payloadData });
  
      if (responseData.success) {
    
        getMediaData();
        setDeleteModalId(null); // Close the modal
        alert('Media item deleted successfully');
      } else {
        // Handle specific error messages returned from the server
        alert(responseData.message || 'Failed to delete media item. Please try again.');
      }
    } catch (err) {
      console.log('Error in deleting media item:', err);
      alert('An error occurred while trying to delete the media item. Please try again.');
    } finally{
      setLoading(false);
    }
  };
  

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
    } catch (err) {
      console.log('Failed to copy URL');
    }
  };

  const handleFileTypeChange=(e)=>{
    let datatype=e.target.value;
    try{
      setMediaType(datatype);
      setFileType(datatype);

    }catch(err){
      console.log('Error in handleling file type',err)
    }
  }

  return (
    <>
      <div className="Template_id_contian2">
        <h4 className="Head_title2">
          Manage Media
          <button className="add_btn2 WMadd_btn" data-bs-toggle="modal" data-bs-target="#Preview_modal">
            <img src={Plus} alt="img" /> Add Media
          </button>
        </h4>
        <div className="Template_id_Card2">
          <div className="table_contain">
            <table className="Table" id="Table">
              <thead>
                <tr>
                  <th>S_No.</th>
                  <th>Date Time</th>
                  <th>Media Title</th>
                  <th>Type</th>
                  <th>URL</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tabledata.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.cdate} <br /> {item.ctime}</td>
                    <td>{item.media_title}</td>
                    <td>{item.media_type}</td>
                    <td>
                      <div className="MangeMediaUrlTextContian">
                      <textarea className='MangeMediaUrlText' readOnly value={item.media_url}></textarea>
                     
                      <button className="copy_icon mt-0" onClick={() => copyToClipBoard(item.media_url)}>
                        <img src={Copy} alt="img" />
                      </button>
                      </div>
                    </td>
                    <td>
                    <Link to={item.media_url} target="_blank" className="download_icon">
                        <img src={Eye} alt="preview" />
                      </Link>
                      <button type="button" className='ms-2' onClick={() => setDeleteModalId(item.id)}>
                        <img src={Delete} alt="img" />
                      </button>
                      {deleteModalId === item.id && (
                        <div className="DeleteConfirmModalContain">
                          <div className="DeleteConfirmModal">
                            <p>Are you sure you want to delete?</p>
                            <div className="modal-buttons">
                              <button type='button' className='Modal_save_btn download_icon' onClick={() => DeleteFunction(item.id)}>Yes</button>
                              <button type="button" className="Modal_cancel_btn" onClick={() => setDeleteModalId(null)}>No</button>
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="modal fade" id="Preview_modal" aria-labelledby="Preview_modal_Label" aria-hidden="true">
        <div className="modal-dialog maxW400">
          <div className="modal-header">
            <h5 className="modal-title text-center w-100" id="Preview_modal_Label">Add Media</h5>
          </div>
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="Modal_Table_contain">
                <div className="FormGroup">
                  <label htmlFor="title">Media title <span className="required_icon">*</span></label>
                  <input type="text" id="title" value={mediaTitle} onChange={(e) => setMediaTitle(e.target.value)} placeholder="Enter Media Title" />
                </div>
                <div className="FormGroup">
                  <label htmlFor="type">Media type <span className="required_icon">*</span></label>
                  <select name="type" id="type" value={mediaType} onChange={handleFileTypeChange}>
                    <option>---Select---</option>
                    <option value="Document">Document</option>
                    <option value="Media">Media</option>
                    <option value="Video">Video</option>
                  </select>
                </div>
                <div className="FormGroup">
                  <label htmlFor="file">Attach file <span className="required_icon">*</span></label>
                  <input type="file" id="file" onChange={handleSaveFile}  accept={fileType === 'Document' ? 'application/pdf' : fileType === 'Media' ? 'image/*' : fileType === 'Video' ? 'video/*' : ''}/>
                </div>
                <div className="modal-buttons">
                  <button type='button' className='Modal_save_btn' onClick={handleSave}>Save</button>
                  <button type="button" id='closeModal' className="Modal_cancel_btn ms-2" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}
    </>
  );
};

export default WbManageMedia;
