import React, { useState, useEffect, useCallback } from "react";
import "../../css/manage_waba.css";
import { apiCall } from "../../services/authServieces";
import ShowSnackBar from "../../components/snackBar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "../../Assets/images/search.png";
import { useUIContext } from "../../context";
import CustomDateRangePicker from "../../components/CustomDatePicker";

import { formatDatePickerDate } from "../../utils/helper";
import { ClipLoader } from "react-spinners";


const TemplateWiseReport = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const { dateRanges } = useUIContext();
  const formatInitialDate = formatDatePickerDate(dateRanges, "temp");
  const startDate = formatInitialDate[0];

  const formatEndingDate = formatDatePickerDate(dateRanges, "temp");
  const endDate = formatEndingDate[1];

  const location = useLocation();
  const [page, setPage] = useState(0); // Pagination state
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const { selectedRow } = location.state || {}; // Get selectedRow if available
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm); // Debounced search term
  const navigate = useNavigate();

  const columns = [
    { id: "created_by", label: "User ID", minWidth: 100 },
    { id: "user_name", label: "User Name", minWidth: 100 },
    { id: "dealer_code", label: "Dealer Code", minWidth: 100 },
    { id: "total_data", label: "Total", minWidth: 100 },
    { id: "delivered_msgs", label: "Delivered", minWidth: 100 },
    { id: "failed_msgs", label: "Failed", minWidth: 100 },
    { id: "read_msgs", label: "Read", minWidth: 100 },
  ];

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [isDataEmpty, setIsDataEmpty] = useState(false); // Track if data is empty after search

  // Update the page when pagination changes
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Update rows per page when user changes it
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset to first page when rows per page changes
  };

  // Close snack bar when it's dismissed
  const handleCloseSnackBar = (event, reason) => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };

  // Debounced search to prevent frequent API calls
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Fetch data based on filters
  const fetchData = useCallback(async () => {
    setIsDataEmpty(false); // Reset the empty data state before each fetch
    setLoading(true);
    try {
      let endpoint = `oem/get-templatewisedata?userId=${debouncedSearchTerm}&page=${page}&limit=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}`;

      // Add search term if available
      if (debouncedSearchTerm) {
        endpoint = `${endpoint}&search=${debouncedSearchTerm}`;
      }

      const response = await apiCall({ method: "GET", endpoint: endpoint });

      if (response.success) {
        // Flatten the data grouped by `created_by` into an array
        const flattenedData = Object.keys(response.data).map((userId) => {
          return response.data[userId].map((item) => ({
            created_by: userId.trim(),  // Trim any spaces from `created_by`
            temp_name: item.temp_name,
            user_name: item.user_name,
            dealer_code: item.dealer_code,
            total_data: item.total_data,
            delivered_msgs: item.delivered_msgs,
            failed_msgs: item.failed_msgs,
            read_msgs: item.read_msgs,
          }));
        }).flat(); // Flatten the array of arrays
        setRows(flattenedData);
        setTotal(response.total); // Set total records count for pagination
        setIsDataEmpty(flattenedData.length === 0); // Set empty data flag if no rows returned
      }
    } catch (error) {
      console.error(error);
    } finally{
      setLoading(false);
    }
  }, [page, rowsPerPage, debouncedSearchTerm, startDate, endDate]);

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Navigate to another page on row click (detailed report)
  const handleRowClick = (row) => {
    const userId = row?.created_by;
    navigate(`/oem/get-templatewisedata/${userId}`, { state: { rowData: row } });
  };

  // Format current date in IST
  const getCurrentDateInIST = () => {
    const now = new Date();
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Intl.DateTimeFormat("en-IN", options).format(now);
  };

  // Trigger data fetch when page or rowsPerPage changes
  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, debouncedSearchTerm]); // Fetch when page or rowsPerPage changes

  return (
    <>
      <div className="Template_id_contian">
        <h4 className="Head_title AdminSearchContain DailyReport">
          <div className="TodayDeliveryRepot">
            <div className="DateRangeContain">
              <input
                placeholder="Enter Userid"
                type="text"
                className=""
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <CustomDateRangePicker rangeKey="temp" />
              <button type="button" className="SearchDateRange" onClick={fetchData}>
                <img src={Search} alt="Search" />
              </button>
            </div>
          </div>
          Template Wise Report
        </h4>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" className="Table DailyReportTable">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isDataEmpty ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No Data Found
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.created_by + row.temp_name + row.send_date} // Ensure key is unique
                      onClick={() => handleRowClick(row)}
                      style={{ cursor: "pointer" }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return <TableCell key={column.id}>{value}</TableCell>;
                      })}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}
      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default TemplateWiseReport;
