import React, { useState, useEffect, useCallback } from "react";
import DownloadIcon from "../Assets/images/import.png";
import TablePagination from "@mui/material/TablePagination";
import { useLocation } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import { apiCall } from "../services/authServieces";
import ShowSnackBar from "../components/snackBar";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import "../css/manage_waba.css";
import "../css/wb_report_details.css";
import { formatDate } from "../utils/helper";
import CustomDateRangePicker from "../components/CustomDatePicker";
import Search from "../Assets/images/search.png";
import { useUIContext } from "../context";
import baseURL from "../config";
import { ClipLoader } from "react-spinners";
import { AirlineSeatLegroomExtraOutlined } from "@mui/icons-material";

const CampaignWiseReport = () => {
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rowData = location.state?.rowData;

  const columns = [
    { id: "send_date", label: "Send Date", minWidth: 100 },
    { id: "campaign_title", label: "Campaign Title", minWidth: 100 },
    { id: "total_count", label: "Total", minWidth: 100 },
    { id: "count_delivered", label: "Delivered", minWidth: 100 },
    { id: "count_failed", label: "Failed", minWidth: 100 },
    { id: "count_seen", label: "Seen", minWidth: 100 },
    { id: "download", label: "Download", minWidth: 100 },
  ];

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { dateRanges } = useUIContext();
  const dates = dateRanges.mis.map(date => {
    if (!date) return undefined;

    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  });

  const [startDate, endDate] = dates;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseSnackBar = (event, reason) => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };

  const fetchData = useCallback(async () => {

    try {
        if (!startDate || !endDate) {
            console.log('Start Date or End Date is missing. API call will not be triggered.');

      return;  // Do nothing if either startDate or endDate is missing
          }
          setLoading(true);

      let endpoint = `user_campaign_report/?page=${page + 1}&limit=${rowsPerPage}`;
      if (startDate && endDate) {
        endpoint = `${endpoint}&startDate=${startDate}&endDate=${endDate}`;
      }
      const response = await apiCall({ method: "GET", endpoint: endpoint });
      console.log("API response:", response); // Debugging API response
      if (response.success) {
        setRows(response.data);
        setTotal(response.total);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error in fetching data:", err);
      setLoading(false);
    }
  }, [page, rowsPerPage, startDate, endDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDateClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let endpoint = `user_campaign_report/?page=${page}&limit=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}`;
      const response = await apiCall({ endpoint: endpoint, method: "GET" });
      if (response.success) {
        setRows(response.data);
        setTotal(response.total);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDownloadClick = async (date, campaignTitle) => {
    setLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("user-cred"));
      const baseUrl = baseURL;  // Assuming baseURL is already defined
      const endpoint = `api/campaign_report?date=${date}&campaign=${campaignTitle}`;
  
      const url = `${baseUrl}/${endpoint}`;
  
      // Fetch the report using axios
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: `Bearer ${user?.token}`,
        },
      });
  
      // Handle empty response (e.g., no file)
      if (response.data.size === 0) {
        throw new Error("Received empty file");
      }
  
      // Extract filename from Content-Disposition header or use a default
      const contentDisposition = response.headers["content-disposition"];
      let filename = `CampaignReport_${campaignTitle}_${date}.xlsx`;
  
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1].replace(/['"]/g, "");
        }
      }
  
      // Create a temporary link element to trigger the download
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = downloadUrl;
      link.download = filename;
  
      document.body.appendChild(link);
      link.click();
  
      setTimeout(() => {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Campaign Report downloaded successfully",
        });
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      }, 100);
    } catch (error) {
      console.error("Error downloading Excel file:", error);

      let errorMessage = "Failed to download the report. ";
      if (error.response && error.response.status === 401) {
        errorMessage += "Please log in again.";
      } else {
        errorMessage += "Please try again.";
      }
  
      setSnackBar({
        open: true,
        severity: "error",
        message: errorMessage,
      });
    }finally{
      setLoading(false);
    }
  };
  

  return (
    <>
      <div className="Template_id_contian">
        <h4 className="Head_title  ReportDetails new_ReportDetails">
          <div className="DateRangeContain new_DateRangeContain">
            <CustomDateRangePicker rangeKey="mis" />
            <button
              type="button"
              className="SearchDateRange"
              onClick={handleDateClick}
            >
              <img src={Search} alt="Search" />
            </button>
          </div>
          <span>{rowData?.user_name || "Campaign Report"}</span>
        </h4>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" className="Table DeliveryReapotTable">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
        <TableBody>
  {rows.map((row, index) => (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.id || `${row.campaign_title}-${row.send_date}-${index}`}>
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell
            key={`${row.id}-${column.id}`}  // Unique key for each column within the row
            className={column.id === "download" ? "downloadIcon" : ""}
          >
            {column.id === "download" ? (
              <button>
                <img
                  src={DownloadIcon}
                  alt="Download"
                  onClick={() => handleDownloadClick(row["send_date"], row["campaign_title"])}
                />
              </button>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  ))}
</TableBody>

            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}

      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default CampaignWiseReport;
