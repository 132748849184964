import React, { useState, useEffect, useCallback } from "react";
import { IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import "../../css/manage_waba.css";
import Plus from "../../Assets/images/plus.png";
import { apiCall } from "../../services/authServieces";
import ShowSnackBar from "../../components/snackBar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { GetPinnacleUser } from "../../services/manageDealerService";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { formatPhoneNumber } from "../../utils/helper";
import SearchIcon from "@mui/icons-material/Search";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ClipLoader } from "react-spinners";
import { useTheme } from "@emotion/react";


const ManageWaba = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [showForm, setShowForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [apiKeyData, setApiKeyData] = useState([]);
  const [rowValue, setSelectedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });
  const [formData, setFormData] = useState({
    userId: "",
    apiKey: "",
    businessNumber: "",
    phoneNumberId: "",
    sessionPrice: "",
    hsmPrice: "",
    plateFormPrice: "",
    utilityPrice: "",
    marketingPrice: "",
    otpPrice: "",
    tpsdata: "",
    whtsapplimit: ""

  });
  const [errors, setErrors] = useState({}); // State for errors
  const [open, setOpen] = useState(false);

  const [usertps, setusertps] = useState('');

  const [whtsapplimit, setwhtsapplimit] = useState('');


  const [tpsmodal, settpsmodal] = useState(false);

  const location = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { selectedRow } = location.state || {};
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const columns = [
    { id: "business_no", label: "Business Num", minWidth: 100 },
    { id: "sess_price_24", label: "Session Price", minWidth: 100 },
    { id: "hsm_price", label: "HSM Price", minWidth: 100 },
    { id: "plt_price", label: "Platform Price", minWidth: 100 },
    { id: "utility_price", label: "Utility Price", minWidth: 100 },
    { id: "marketing_price", label: "Marketing Price", minWidth: 100 },
    { id: "otp_price", label: "OTP Price", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  const [sessionPrice, setsessionPrice] = useState('')
  const [otpPrice, setotpPrice] = useState('')
  const [marketngPrice, setmarketngPrice] = useState('')
  const [utilityPrice, setutilityPrice] = useState('')
  const [pltfrm_price, setpltfrm_price] = useState('')
  const [hsmPrice, sethsmPrice] = useState('')
  const [businessNumber, setbusinessNumber] = useState('')


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };



  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseSnackBar = (event, reason) => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    settpsmodal(false);
    // setSelectedRow(null);
  };

  const handleMenuAction = (action) => {
    if (action === "edit") {
      // Trigger the modal
      setOpen(true)

      var sess_price_24 = rowValue.sess_price_24;
      var otp_price = rowValue.otp_price;
      var plt_price = rowValue.plt_price;
      var marketing_price = rowValue.marketing_price;
      var utility_price = rowValue.utility_price;
      var hsm_price = rowValue.hsm_price;
      var business_no = rowValue.business_no;

      setsessionPrice(sess_price_24)
      sethsmPrice(hsm_price)
      setpltfrm_price(plt_price)
      setutilityPrice(utility_price)
      setmarketngPrice(marketing_price)
      setotpPrice(otp_price)
      setbusinessNumber(business_no)


    }
    if (action === 'edit_tps') {

      settpsmodal(true)

      var business_no = rowValue.business_no;

      getlimitdata(business_no)

      setbusinessNumber(business_no)

    }

    // if(action ==='delete'){


    //   console.log('delete')


    // }
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // 500ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Fetch data based on debounced search term
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      let endpoint = `manage-waba/get-wabas?page=${page + 1
        }&limit=${rowsPerPage}&userId=${selectedRow.user_id}`;
      if (debouncedSearchTerm) {
        endpoint = `${endpoint}&search=${debouncedSearchTerm}`;
        console.log('endpoint: ', endpoint);
      }
      const response = await apiCall({ method: "GET", endpoint: endpoint });
      if (response.success) {
        setRows(response.data);
        setTotal(response.total);
      }
    } catch (error) {
      console.error(error);
    }finally{
      setLoading(false);
    }
  }, [page, rowsPerPage, selectedRow.user_id, debouncedSearchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);





  const getWhatsAppData = useCallback(async () => {
    setLoading(true);
    try {
      const apiKey = selectedRow.api_key;
      if (apiKey) {
        const res = await GetPinnacleUser(apiKey, "");
        if (res.success) {
          setApiKeyData(res.data);
          console.log("res.data: ", res.data);
          setFormData((prevFormData) => ({
            ...prevFormData,
            apiKey: apiKey, // Assuming res.data has an apiKey field
          }));
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }finally{
      setLoading(false);
    }
  }, [selectedRow]);

  useEffect(() => {
    getWhatsAppData();
  }, [getWhatsAppData]);

  const toggleModal = () => {
    setErrors({});
    setFormData("");
    setIsModalOpen(!isModalOpen);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const validateForm = () => {
    let validationErrors = {};
    if (!selectedRow.api_key) validationErrors.apiKey = "API Key is required";
    if (!formData.hsmPrice) validationErrors.hsmPrice = "HMS price is required";
    if (!formData.sessionPrice)
      validationErrors.sessionPrice = "Session Price is required";
    if (!formData.plateFormPrice)
      validationErrors.plateFormPrice = "Platform Price is required";
    if (!formData.businessNumber)
      validationErrors.businessNumber = "Business Number is required";
    if (!formData.phoneNumberId)
      validationErrors.phoneNumberId = "Phone Number Id is required";
    if (!formData.otpPrice) validationErrors.otpPrice = "Otp price is required";
    if (!formData.marketingPrice)
      validationErrors.marketingPrice = "Marketing price is required";
    if (!formData.utilityPrice)
      validationErrors.utilityPrice = "Utility price is required";
    if (!formData.tpsdata)
      validationErrors.tpsdata = "User TPS is required";
    if (!formData.whtsapplimit)
      validationErrors.whtsapplimit = "Whtsapp limit is required";

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; // Stop if validation fails
    setLoading(true);
    try {
      const response = await apiCall({
        method: "POST",
        endpoint: "manage-waba/add-waba",
        payload: {
          userId: selectedRow.user_id,
          apiKey: selectedRow.api_key,
          businessNum: formData.businessNumber,
          sessionPrice: formData.sessionPrice,
          plateFormPrice: formData.plateFormPrice,
          hsmPrice: formData.hsmPrice,
          phoneNumberId: formData.phoneNumberId,
          utilityPrice: formData.utilityPrice,
          marketingPrice: formData.marketingPrice,
          otpPrice: formData.otpPrice,
          usertps: formData.tpsdata,
          whtsapplimit: formData.whtsapplimit
        },
      });
      if (response.success) {
        setSnackBar({
          open: true,
          severity: true,
          message: "Record added successfully.",
        });
        toggleModal();
        fetchData();
        setPage(0);
      } else {
        console.error("Failed to add WABA:", response.message);
      }
    } catch (err) {
      setSnackBar({
        open: true,
        severity: false,
        message: err?.response?.data?.message || "An error occurred",
      });
    } finally{
      setLoading(false);
    }
  };

  const handleCheckBusinessNumber = (e) => {
    e.preventDefault();
    if (formData?.businessNumber && apiKeyData && apiKeyData.length) {
      const wabaNumber = formatPhoneNumber(formData.businessNumber);
      const phoneNumberData = apiKeyData.filter(
        (el) => el.wanumber === wabaNumber
      );
      const phoneNumberId =
        phoneNumberData && phoneNumberData.length
          ? phoneNumberData[0]?.phone_number_id
          : "";

      if (phoneNumberId) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          phoneNumberId: phoneNumberId, // Assuming res.data has an apiKey field
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          phoneNumberId: "", // Assuming res.data has an apiKey field
        }));
        setSnackBar({
          open: true,
          severity: false,
          message: "Please check the business number ID.",
        });
      }
    }
  };



  const getlimitdata = async (business_no) => {
    setLoading(true);
    try {

      let businessNum = business_no;

      let responseData = await apiCall({ endpoint: 'manage-waba/getusertpsandlimit', method: 'POST', payload: { businessNum: businessNum } })

      if (responseData.success) {

        console.log(responseData.data, 'responsedata')


        let usertps = responseData?.data[0].user_tps ?? '0'
        let whtsapp_limit = responseData?.data[0].whtsapp_limit ?? '0'


        setusertps(usertps)

        setwhtsapplimit(whtsapp_limit)

      }


    } catch (err) {


      console.log(err, 'error in fecthing user tps data')


    } finally{
      setLoading(false);
    }
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleUpdatePrice = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation checks
    if (sessionPrice.trim() === '') {
      setSnackBar({
        open: true,
        severity: false,
        message: 'Session Price cannot be empty',
      });
      return;
    }
    if (hsmPrice.trim() === '') {
      setSnackBar({
        open: true,
        severity: false,
        message: 'HSM Price cannot be empty',
      });
      return;
    }
    if (pltfrm_price.trim() === '' || isNaN(pltfrm_price)) {
      setSnackBar({
        open: true,
        severity: false,
        message: 'Platform Price must be a valid number',
      });
      return;
    }
    if (utilityPrice.trim() === '' || isNaN(utilityPrice)) {
      setSnackBar({
        open: true,
        severity: false,
        message: 'Utility Price must be a valid number',
      });
      return;
    }
    if (marketngPrice.trim() === '' || isNaN(marketngPrice)) {
      setSnackBar({
        open: true,
        severity: false,
        message: 'Marketing Price must be a valid number',
      });
      return;
    }
    if (otpPrice.trim() === '' || isNaN(otpPrice)) {
      setSnackBar({
        open: true,
        severity: false,
        message: 'OTP Price must be a valid number',
      });
      return;
    }

    // Constructing the payload
    let payload = {
      sessionPrice: parseFloat(sessionPrice),
      hsmPrice: parseFloat(hsmPrice),
      platformPrice: parseFloat(pltfrm_price),
      utilityPrice: parseFloat(utilityPrice),
      marketingPrice: parseFloat(marketngPrice),
      otpPrice: parseFloat(otpPrice),
      businessNumber: businessNumber,
    };

    // Making the API call
    try {
      let responseData = await apiCall({
        endpoint: 'manage-waba/updatepriceforuser',
        method: 'POST',
        payload: payload,
      });

      // Handle response
      if (responseData.success) {

        console.log('true')
        setSnackBar({
          open: true,
          severity: true,
          message: 'Update successful!',
        });
        fetchData();

        setOpen(false); // Close the modal
        // Resetting modal values
        setsessionPrice('');
        sethsmPrice('');
        setpltfrm_price('');
        setutilityPrice('');
        setmarketngPrice('');
        setotpPrice('');
        setbusinessNumber('');// Refresh data
      } else {
        setSnackBar({
          open: true,
          severity: false,
          message: responseData.msg || 'Update failed. Please try again.',
        });
      }
    } catch (err) {
      console.log('Error in Updating', err);
      setSnackBar({
        open: true,
        severity: false,
        message: 'An error occurred while updating the prices.',
      });
    } finally{
      setLoading(false);
    }
  };


  const handleUpdatetps = async () => {
    setLoading(true);
    try {

      if (usertps.trim() === '') {
        setSnackBar({
          open: true,
          severity: false,
          message: 'User Tps cannot be empty',
        });
        return;
      }
      if (whtsapplimit.trim() === '') {
        setSnackBar({
          open: true,
          severity: false,
          message: 'Whtsapp Limit cannot be empty',
        });
        return;
      }


      let payload = {
        usertps: parseFloat(usertps),
        whtsapplimit: parseFloat(whtsapplimit),
        businessNumber: businessNumber
      };

      let responseData = await apiCall({
        endpoint: 'manage-waba/updateusertpsandlimit',
        method: 'POST',
        payload: payload,
      });


      if (responseData.success) {

        setSnackBar({
          open: true,
          severity: true,
          message: 'Update successful!',
        });

        settpsmodal(false);


      } else {

        setSnackBar({
          open: true,
          severity: false,
          message: responseData.msg || 'Update failed. Please try again.',
        });


      }





    } catch (err) {

      console.log('Error in Updating', err);
      setSnackBar({
        open: true,
        severity: false,
        message: 'An error occurred while updating the Limit.',
      });


    } finally{
      setLoading(false);
    }
  }

  return (
    <>
      <div className="Template_id_contian">
        <h4 className="Head_title AdminSearchContain text_capitalize">
          <span className="user_id">{selectedRow.user_id}</span>
          Manage Waba
          <div className="Session_report_SearchContain">
            <input
              placeholder="Search"
              type="text"
              className=""
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="button"><SearchIcon /></button>
          </div>
          <button className="add_btn6" onClick={toggleModal}>
            <img src={Plus} alt="img" /> New
          </button>
        </h4>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" className="Table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id}>
                          {column.id === "action" ? (
                            <>
                              <IconButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={(event) => handleClick(event, row)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  onClick={() => handleMenuAction("edit")}
                                >
                                  Edit
                                </MenuItem>

                                <MenuItem
                                  onClick={() => handleMenuAction("edit_tps")}
                                >
                                  Edit Tps & Limit
                                </MenuItem>
                                {/* <MenuItem
                                  onClick={() => handleMenuAction("delete")}
                                >
                                  Delete
                                </MenuItem> */}
                              </Menu>
                            </>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay manage_dealer_form waba_form ">
          <div className="maxW700">
            <div className="modal-header">
              <h4 className="modal-title text-center w-100">Add New WABA</h4>
            </div>
            <div className="modal-content">

              <form onSubmit={handleSubmit}>
                <div className="form-container">
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="userId">User ID:</label>
                      <input
                        type="text"
                        id="userId"
                        value={selectedRow.user_id || ""}
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="apiKey">API Key:</label>
                      <input
                        type="text"
                        id="apiKey"
                        placeholder="Enter API Key"
                        value={selectedRow.api_key}
                        readOnly
                        onChange={handleInputChange}
                      />
                      {errors.apiKey && (
                        <span className="error-msg">{errors.apiKey}</span>
                      )}
                    </div>
                    <div className="form-group bussiness_num">
                      <label htmlFor="businessNumber" className="form-label">
                        Business Number:
                      </label>
                      <input
                        type="text"
                        id="businessNumber"
                        placeholder="Business Number"
                        value={formData.businessNumber}
                        onChange={handleInputChange}
                        className="input-field"
                      />
                      <button
                        type="button"
                        onClick={handleCheckBusinessNumber}
                        className="check-button"
                      >
                        Check
                      </button>
                      {errors.businessNumber && (
                        <span className="error-msg">{errors.businessNumber}</span>
                      )}
                    </div>

                  </div>

                  {/* <div className="form-row">
                </div> */}


                  <p className="fill_note">Please check to fill below form</p>
                  <div className="form-row">
                    {formData?.phoneNumberId && (
                      <div className="form-group">
                        <label htmlFor="userId">Phone Number ID:</label>
                        <input
                          type="text"
                          id="phoneNumberId"
                          // onChange={handleInputChange}
                          value={formData.phoneNumberId || ""}
                          readOnly
                        />

                        {errors.phoneNumberId && (
                          <span className="error-msg">
                            {errors.phoneNumberId}
                          </span>
                        )}
                      </div>
                    )}
                    <div className="form-group">
                      <label htmlFor="userId">Session Price:</label>
                      <input
                        type="number"
                        id="sessionPrice"
                        onChange={handleInputChange}
                        value={formData.sessionPrice || ""}
                        readOnly={!formData?.phoneNumberId}
                      />

                      {errors.sessionPrice && (
                        <span className="error-msg">{errors.sessionPrice}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="userId">HSM Price</label>
                      <input
                        type="number"
                        id="hsmPrice"
                        onChange={handleInputChange}
                        value={formData.hsmPrice || ""}
                        readOnly={!formData?.phoneNumberId}
                      />

                      {errors.hsmPrice && (
                        <span className="error-msg">{errors.hsmPrice}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="userId">Platform Price:</label>
                      <input
                        type="number"
                        id="plateFormPrice"
                        onChange={handleInputChange}
                        value={formData.plateFormPrice || ""}
                        readOnly={!formData?.phoneNumberId}
                      />

                      {errors.plateFormPrice && (
                        <span className="error-msg">{errors.plateFormPrice}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="userId">Utility Price:</label>
                      <input
                        type="number"
                        id="utilityPrice"
                        onChange={handleInputChange}
                        value={formData.utilityPrice || ""}
                        readOnly={!formData?.phoneNumberId}
                      />

                      {errors.utilityPrice && (
                        <span className="error-msg">{errors.utilityPrice}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="userId">Marketing Price:</label>
                      <input
                        type="number"
                        id="marketingPrice"
                        onChange={handleInputChange}
                        value={formData.marketingPrice || ""}
                        readOnly={!formData?.phoneNumberId}
                      />

                      {errors.marketingPrice && (
                        <span className="error-msg">{errors.marketingPrice}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="userId">Otp Price:</label>
                      <input
                        type="number"
                        id="otpPrice"
                        onChange={handleInputChange}
                        value={formData.otpPrice || ""}
                        readOnly={!formData?.phoneNumberId}
                      />

                      {errors.otpPrice && (
                        <span className="error-msg">{errors.otpPrice}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="tpsdata">User Tps:</label>
                      <input
                        type="number"
                        id="tpsdata"
                        onChange={handleInputChange}
                        value={formData.tpsdata || ""}
                        readOnly={!formData?.phoneNumberId}
                      />

                      {errors.tpsdata && (
                        <span className="error-msg">{errors.tpsdata}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="whtsapplimit">Whtsapp Limit:</label>
                      <input
                        type="number"
                        id="whtsapplimit"
                        onChange={handleInputChange}
                        value={formData.whtsapplimit || ""}
                        readOnly={!formData?.phoneNumberId}
                      />

                      {errors.whtsapplimit && (
                        <span className="error-msg">{errors.whtsapplimit}</span>
                      )}
                    </div>

                  </div>
                </div>
                <div className="form-actions button_btn_submit">
                  <button type="submit">Submit</button>
                  <button type="button" onClick={toggleModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
      >
        <Box sx={modalStyle} className="modal_box_new box_m_new">
          <h2 id="edit-modal-title">Edit Price</h2>
          <div className="form-container">

            <div className="form-row">
              <div className="form-group">
                <TextField
                  label="Business No"
                  id="BusinessNo"
                  type="number"
                  value={businessNumber}
                  onChange={e => setbusinessNumber(e.target.value)}
                  disabled
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  label="Session Price"
                  id="sessionPrice"
                  type="number"
                  value={sessionPrice}
                  onChange={e => setsessionPrice(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  label="HSM Price"
                  id="hsmPrice"
                  type="number"
                  value={hsmPrice}
                  onChange={e => sethsmPrice(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  label="Platform Price"
                  id="plateFormPrice"
                  type="number"
                  value={pltfrm_price}
                  onChange={e => setpltfrm_price(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <TextField
                  label="Utility Price"
                  id="utilityPrice"
                  type="number"
                  value={utilityPrice}
                  onChange={e => setutilityPrice(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  label="Marketing Price"
                  id="marketingPrice"
                  type="number"
                  value={marketngPrice}
                  onChange={e => setmarketngPrice(e.target.value)}

                  fullWidth
                />
              </div>
              <div className="form-group">
                <TextField
                  label="OTP Price"
                  id="otpPrice"
                  type="number"
                  value={otpPrice}

                  onChange={e => setotpPrice(e.target.value)}

                  fullWidth
                />
              </div>
            </div>
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>

            <Button variant="contained" className="save_changes_btn save_bt_btn" onClick={handleUpdatePrice}>
              Save changes
            </Button>

            <Button variant="contained" className="close_bt bt_close" sx={{ ml: 2 }} onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
      >
        <div className="parent-modal">
          <div className="parent-modal__overlay" onClick={handleClose}></div>
          <div className="parent-modal__content">
            <div className="modal_header_t">
              <h2>Edit Price</h2>
            </div>

            <div className="parent-modal__form-container">
              <div className="parent-modal__form-row">
                <div className="parent-modal__form-group">
                  <label for="BusinessNo">Business No</label>
                  <input
                    type="number"
                    id="BusinessNo"
                    value={businessNumber}
                    onChange={e => setbusinessNumber(e.target.value)}
                    disabled
                    placeholder="Business No"
                  />
                </div>

                <div className="parent-modal__form-group">
                  <label for="sessionPrice">Session Price</label>
                  <input
                    type="number"
                    id="sessionPrice"
                    value={sessionPrice}
                    onChange={e => setsessionPrice(e.target.value)}
                    placeholder="Session Price"
                  />
                </div>

              </div>
              <div className="parent-modal__form-row">


                <div className="parent-modal__form-group">
                  <label for="hsmPrice">HMS Price</label>
                  <input
                    type="number"
                    id="hsmPrice"
                    value={hsmPrice}
                    onChange={e => sethsmPrice(e.target.value)}
                    placeholder="HSM Price"
                  />
                </div>
                <div className="parent-modal__form-group">
                  <label for="platformPrice">Platform Price</label>
                  <input
                    type="number"
                    id="platformPrice"
                    value={pltfrm_price}
                    onChange={e => setpltfrm_price(e.target.value)}
                    placeholder="Platform Price"
                  />
                </div>
              </div>
              <div className="parent-modal__form-row">

                <div className="parent-modal__form-group">
                  <label for="utilityPrice">Utility Price</label>
                  <input
                    type="number"
                    id="utilityPrice"
                    value={utilityPrice}
                    onChange={e => setutilityPrice(e.target.value)}
                    placeholder="Utility Price"
                  />
                </div>
                <div className="parent-modal__form-group">
                  <label for="marketingPrice">Marketing Price</label>
                  <input
                    type="number"
                    id="marketingPrice"
                    value={marketngPrice}
                    onChange={e => setmarketngPrice(e.target.value)}
                    placeholder="Marketing Price"
                  />
                </div>
                <div className="parent-modal__form-group">
                  <label for="otpPrice">OTP Price</label>
                  <input
                    type="number"
                    id="otpPrice"
                    value={otpPrice}
                    onChange={e => setotpPrice(e.target.value)}
                    placeholder="OTP Price"
                  />
                </div>
              </div>
            </div>
            <div className="parent-modal__actions">
              <button className="parent-modal__save-btn" onClick={handleUpdatePrice}>
                Save Changes
              </button>
              <button className="parent-modal__close-btn" onClick={handleClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal> 





      {/* <Modal
  open={tpsmodal}
  onClose={handleClose}
  aria-labelledby="edit-modal-title"
  aria-describedby="edit-modal-description"
>
  <Box
    sx={{
      ...modalStyle,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      boxShadow: 24,
      padding: 3,
      width: 500, // Modal width
      maxWidth: '90%',
      backgroundColor: '#fff',
      margin: 'auto',
    }}
    className="modal_box_new box_m_new"
  >
    <h2 id="edit-modal-title" className="heading_text_ne" style={{ textAlign: 'center', marginBottom: 20 }}>
      Edit Tps and Limit
    </h2>

    <div className="form-container" style={{ width: '100%' }}>
      <div className="form-row" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div className="form-group">
          <TextField
            label="User Tps"
            id="usertps"
            type="number"
            value={usertps}
            onChange={e => setusertps(e.target.value)}
            fullWidth
            variant="outlined"
            inputProps={{ min: 0 }}
            sx={{ marginBottom: 2 }} // Spacing below input
          />
        </div>
        <div className="form-group">
          <TextField
            label="WhatsApp Limit"
            id="whtsapplimit"
            type="number"
            value={whtsapplimit}
            onChange={e => setwhtsapplimit(e.target.value)}
            fullWidth
            variant="outlined"
            inputProps={{ min: 0 }}
          />
        </div>
      </div>
    </div>

    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2 }}>
      <Button
        variant="contained"
        color="primary"
        className="save_changes_btn save_bt_btn"
        onClick={handleUpdatetps}
        sx={{
          paddingX: 4,
          paddingY: 1.5,
          borderRadius: 4,
          fontSize: 16,
          fontWeight: 'bold',
        }}
      >
        Save Changes
      </Button>

      <Button
        variant="outlined"
        color="secondary"
        className="close_bt bt_close"
        sx={{
          paddingX: 4,
          paddingY: 1.5,
          borderRadius: 4,
          fontSize: 16,
          fontWeight: 'bold',
        }}
        onClick={handleClose}
      >
        Close
      </Button>
    </Box>
  </Box>
</Modal> */}

      

<Modal
  open={tpsmodal}
  onClose={handleClose}
  aria-labelledby="edit-modal-title"
  aria-describedby="edit-modal-description"
>
        <div className="modal_box_new">
          <div className="modal_box_new__overlay" onClick={handleClose}></div>
          <div className="modal_box_new__content box_m_new">
            <div className="modal_header_t">
              <h2 id="edit-modal-title" className="heading_text_ne">Edit Tps and Limit</h2>
            </div>


            <div className="form-container1">
              <div className="parent-modal__form-row">
                <div className="parent-modal__form-group">
                  <label htmlFor="usertps">User Tps</label>
                  <input
                    type="number"
                    id="usertps"
                    value={usertps}
                    onChange={e => setusertps(e.target.value)}
                    placeholder="User Tps"
                  />
                </div>
                <div className="parent-modal__form-group">
                  <label htmlFor="whtsapplimit">WhatsApp Limit</label>
                  <input
                    type="number"
                    id="whtsapplimit"
                    value={whtsapplimit}
                    onChange={e => setwhtsapplimit(e.target.value)}
                    placeholder="WhatsApp Limit"
                  />
                </div>
              </div>
            </div>
            <div className="parent-modal__actions modal__actions_new">
              <button className="parent-modal__save-btn" onClick={handleUpdatetps}>
                Save changes
              </button>
              <button className="parent-modal__close-btn" onClick={handleClose}>
                Close
              </button>
            </div>
          </div>
        </div>
        </Modal>
        {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}

      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};
// Style for the modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default ManageWaba;
