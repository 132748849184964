import React, { useState, useEffect, useCallback } from "react";
import DownloadIcon from "../../Assets/images/import.png";
import TablePagination from "@mui/material/TablePagination";
import { useLocation } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import { apiCall } from "../../services/authServieces";
import ShowSnackBar from "../../components/snackBar";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import "../../css/manage_waba.css";
import "../../css/wb_report_details.css";
import { formatDate } from "../../utils/helper";
import CustomDateRangePicker from "../../components/CustomDatePicker";
import Search from "../../Assets/images/search.png";
import { useUIContext } from "../../context";
import baseURL from "../../config";
import { ClipLoader } from "react-spinners";


const MisOemDetailedReport = () => {
  const [loading, setLoading] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const location = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rowData = location.state?.rowData;
  // const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  

  const columns = [
    { id: "formattedDate", label: "Send Date", minWidth: 100 },
    { id: "total", label: "HSM Send", minWidth: 100 },
    { id: "delivered", label: "HSM Delivered", minWidth: 100 },
    { id: "failed", label: "HSM Failed", minWidth: 100 },
    { id: "hsm_seen", label: "HSM Seen", minWidth: 100 },
    { id: "session_send", label: "Session Send", minWidth: 100 },
    { id: "session_delivered", label: "Session Delivered", minWidth: 100 },
    { id: "session_failed", label: "Session Failed", minWidth: 100 },
    { id: "session_seen", label: "Session Seen", minWidth: 100 },
    { id: "download", label: "Download", minWidth: 100 },
  ];

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const {dateRanges} = useUIContext();
  const dates = dateRanges.mis.map(date => {
    if (!date) return undefined;
  
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  });
  
  const [startDate, endDate] = dates;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseSnackBar = (event, reason) => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDebouncedSearchTerm(searchTerm);
  //   }, 500);

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [searchTerm]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if(rowData?.user_id){
          let endpoint = `oem_detailed-mis-report/${rowData?.user_id}?page=${page}&limit=${rowsPerPage}`;
          // if (debouncedSearchTerm) {
          //   endpoint = `${endpoint}&search=${debouncedSearchTerm}`;
          // }
          if(startDate && endDate){
            endpoint = `${endpoint}&startDate=${startDate}&endDate=${endDate}`;
          }
          const response = await apiCall({ method: "GET", endpoint: endpoint });
          if (response.success) {
            const data = response.data
            if(data && data.length){
              for (let i = 0; i < data.length; i++) {
                const element = data[i];
                const formattedDate = formatDate(element.date)
                console.log('formattedDate: ', formattedDate);
                element['formattedDate'] = formattedDate
              }
            }
            setRows(response.data);
            setTotal(response.total);
          }
      }
    } catch (error) {
      console.error(error);
    } finally{
      setLoading(false);
    }
  }, [page, rowsPerPage,rowData?.created_by]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  // handle date filter change
  const handleDateClick = async(e) =>{
    e.preventDefault();
    setLoading(true);
    try{
      let endpoint = `oem_detailed-mis-report/${rowData?.user_id}?page=${page}&limit=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}`;
      const response = await apiCall({endpoint: endpoint, method: 'GET'});
      if (response.success) {
        const data = response.data
        if(data && data.length){
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            const formattedDate = formatDate(element.date)

            element['formattedDate'] = formattedDate
          }
        }
        setRows(response.data);
        setTotal(response.total);
      }
    }catch(error){
      console.log(error);
    } finally{
      setLoading(false);
    }
  }

  // handle download excel button
  const handleDownloadClick = async (date) => {
    setLoading(true);
    try {
        // Build the URL with query parameters
        const user = JSON.parse(localStorage.getItem("user-cred"));
        const baseUrl = baseURL
        const endpoint = `dashboard/download/misReport?date=${date}&userId=${rowData?.user_id}`;

        const url = `${baseUrl}/${endpoint}`;

        // Make the axios request with bearer token
        const response = await axios.get(url, {
            responseType: 'blob',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Authorization': `Bearer ${user?.token}`
            }
        });

        // Check for empty file
        if (response.data.size === 0) {
            throw new Error('Received empty file');
        }

        // Get filename from the response headers or use default
        const contentDisposition = response.headers['content-disposition'];
        let filename = `MisReport_${date}.xlsx`;

        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            if (filenameMatch && filenameMatch[1]) {
                filename = filenameMatch[1].replace(/['"]/g, '');
            }
        }

        // Create download link
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = downloadUrl;
        link.download = filename;

        // Append to document, click, and cleanup
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          setSnackBar({
            open: true,
            severity: true,
            message: 'MIS Report downloaded Successfully'
          })
          document.body.removeChild(link);
          window.URL.revokeObjectURL(downloadUrl);
        }, 100);

    } catch (error) {
        console.error('Error downloading Excel file:', error);

        // Enhanced error messaging
        let errorMessage = 'Failed to download the report. ';
        if (error.response && error.response.status === 401) {
            errorMessage += 'Please login again.';
        } else {
            errorMessage += 'Please try again.';
        }

        return setSnackBar({
          open: true,
          severity: false,
          message: errorMessage
        })
    } finally{
      setLoading(false);
    }
};


  
  return (
    <>
      <div className="Template_id_contian">
        
        <h4 className="Head_title  ReportDetails new_ReportDetails">
        <div className="DateRangeContain new_DateRangeContain">
        <CustomDateRangePicker rangeKey="mis" /><button type="button" className="SearchDateRange" onClick={handleDateClick}><img src={Search} alt="Search" /></button>
        </div>
        <span>{rowData?.user_name || 'Mis Report'}</span>
{/*           
          <div className="Session_report_SearchContain">
            <input
              placeholder="Search"
              type="text"
              className=""
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="button">
              <SearchIcon />
            </button>
          </div> */}
        </h4>
        
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" className="Table DeliveryReapotTable">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {/* <TableBody>
                {rows.map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    // onClick={() => handleRowClick(row)}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return <TableCell key={column.id}>{value}</TableCell>;
                    })}

                      <td className="download_icon">
                        <button>
                          <img src={DownloadIcon} alt="Download" /> click
                        </button>
                      </td>
                  </TableRow>
                ))}
              </TableBody> */}

              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} className={column.id === "download" ? "downloadIcon" : ""}>
                          {column.id === "download" ? (
                            <button>
                              <img src={DownloadIcon} alt="Download" onClick={() => handleDownloadClick(row['date'])}/>
                            </button>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>

              
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}
      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default MisOemDetailedReport;
