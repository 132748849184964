import React, { useState, useEffect, useCallback } from "react";
import DownloadIcon from "../../Assets/images/import.png";
import TablePagination from "@mui/material/TablePagination";
import { useLocation } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import { apiCall } from "../../services/authServieces";
import ShowSnackBar from "../../components/snackBar";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import "../../css/manage_waba.css";
import "../../css/wb_report_details.css";
import { formatDate } from "../../utils/helper";
import CustomDateRangePicker from "../../components/CustomDatePicker";
import Search from "../../Assets/images/search.png";
import { useUIContext } from "../../context";
import baseURL from "../../config";
import { ClipLoader } from "react-spinners";


const OemTemplateDetailedReport = () => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1); // Start pagination from 1
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(0);
    const [snackBar, setSnackBar] = useState({ open: false, severity: "success", message: "" });
  
    const location = useLocation();
    const rowData = location.state?.rowData;
    const { dateRanges } = useUIContext();
  
    const dates = dateRanges.temp.map((date) => {
      if (!date) return undefined;
      const localDate = new Date(date);
      const year = localDate.getFullYear();
      const month = String(localDate.getMonth() + 1).padStart(2, "0");
      const day = String(localDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    });
  
    const [startDate, endDate] = dates;
  
    const columns = [
      { id: "send_date", label: "Send Date", minWidth: 100 },
      { id: "temp_name", label: "Template Name", minWidth: 100 },
      { id: "temp_category", label: "Template Type", minWidth: 100 },
      { id: "total_count", label: "Total", minWidth: 100 },
      { id: "count_delivered", label: "Delivered", minWidth: 100 },
      { id: "count_failed", label: "Failed", minWidth: 100 },
      { id: "count_seen", label: "Read", minWidth: 100 },
    ];
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage + 1); // React uses 0-indexed pages, so we add 1 to make it 1-indexed for backend
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(1); // Reset to first page when rows per page change
    };
  
    const handleCloseSnackBar = () => {
      setSnackBar({ ...snackBar, open: false });
    };
  
    const fetchData = useCallback(async () => {
      setLoading(true);
      try {
        if (rowData?.created_by) {
          let endpoint = `oem_detailed-templatewise-report/${rowData?.created_by}?page=${page}&limit=${rowsPerPage}`;
          if (startDate && endDate) {
            endpoint = `${endpoint}&startDate=${startDate}&endDate=${endDate}`;
          }
  
          const response = await apiCall({ method: "GET", endpoint: endpoint });
          if (response.success) {
            setRows(response.data);
            setTotal(response.total);
          }
        }
      } catch (error) {
        console.error(error);
      } finally{
        setLoading(false);
      }
    }, [page, rowsPerPage, rowData?.created_by]);
  
    useEffect(() => {
      fetchData();
    }, [fetchData]);
  
    const handleDateClick = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        let endpoint = `oem_detailed-templatewise-report/${rowData?.created_by}?page=${page}&limit=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}`;
        const response = await apiCall({ endpoint: endpoint, method: "GET" });
        if (response.success) {
          setRows(response.data);
          setTotal(response.total);
        }
      } catch (error) {
        console.log(error);
      } finally{
        setLoading(false);
      }
    };
  
    const handleDownloadClick = async (date) => {
      setLoading(true);
      try {
        const user = JSON.parse(localStorage.getItem("user-cred"));
        const baseUrl = baseURL;
        const endpoint = `dashboard/download/misReport?date=${date}&userId=${rowData?.user_id}`;
        const url = `${baseUrl}/${endpoint}`;
  
        const response = await axios.get(url, {
          responseType: "blob",
          headers: {
            Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            Authorization: `Bearer ${user?.token}`,
          },
        });
  
        if (response.data.size === 0) {
          throw new Error("Received empty file");
        }
  
        const contentDisposition = response.headers["content-disposition"];
        let filename = `MisReport_${date}.xlsx`;
  
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1].replace(/['"]/g, "");
          }
        }
  
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = downloadUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
  
        setTimeout(() => {
          setSnackBar({
            open: true,
            severity: true,
            message: "MIS Report downloaded Successfully",
          });
          document.body.removeChild(link);
          window.URL.revokeObjectURL(downloadUrl);
        }, 100);
      } catch (error) {
        console.error("Error downloading Excel file:", error);
        setSnackBar({
          open: true,
          severity: false,
          message: "Failed to download the report. Please try again.",
        });
      } finally{
        setLoading(false);
      }
    };
  
    return (
      <>
        <div className="Template_id_contian">
          <h4 className="Head_title  ReportDetails new_ReportDetails">
            <div className="DateRangeContain new_DateRangeContain">
              <CustomDateRangePicker rangeKey="temp" />
              <button type="button" className="SearchDateRange" onClick={handleDateClick}>
                <img src={Search} alt="Search" />
              </button>
            </div>
            <span>{rowData?.user_name || "Mis Report"}</span>
          </h4>
  
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table" className="Table DeliveryReapotTable">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
  
                <TableBody>
                  {rows.map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} className={column.id === "download" ? "downloadIcon" : ""}>
                            {column.id === "download" ? (
                              <button>
                                <img src={DownloadIcon} alt="Download" onClick={() => handleDownloadClick(row["date"])} />
                              </button>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
  
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page - 1} // Convert to 0-indexed for the pagination component
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
        {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}
        <ShowSnackBar
          open={snackBar.open}
          severity={snackBar.severity}
          message={snackBar.message}
          onClose={handleCloseSnackBar}
        />
      </>
    );
  };
  
  export default OemTemplateDetailedReport;
