import React, { useState, useEffect, useCallback } from "react";
import "../../css/manage_waba.css";
import { apiCall } from "../../services/authServieces";
import ShowSnackBar from "../../components/snackBar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import Search from "../../Assets/images/search.png";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDateRangePicker from "../../components/CustomDatePicker";
import { useUIContext } from "../../context";
import excelIcon from "../../Assets/images/excel.png";
import baseURL from "../../config";
import axios from "axios";
import { formatDatePickerDate } from "../../utils/helper";
import { ClipLoader } from "react-spinners";




const OEMMisReport = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });


  const location = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { selectedRow } = location.state || {};
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const navigate = useNavigate();

  const columns = [
    { id: "user_id", label: "User ID", minWidth: 100 },
    { id: "user_name", label: "Name", minWidth: 100 },
    { id: "company_name", label: "Company", minWidth: 100 },
    { id: "delivered", label: "Delivered", minWidth: 100 },
    { id: "failed", label: "Failed", minWidth: 100 },
    { id: "total", label: "Total", minWidth: 100 },
  ];

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { dateRanges } = useUIContext();

  const formatInitialDate = formatDatePickerDate(dateRanges, "mis");
  const startDate=formatInitialDate[0];

  const formatEndingDate = formatDatePickerDate(dateRanges, "mis");
  const endDate=formatEndingDate[1];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseSnackBar = (event, reason) => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      let endpoint = `oem/get-mis-report?page=${page}&limit=${rowsPerPage}`;
      if (debouncedSearchTerm) {
        endpoint = `${endpoint}&search=${debouncedSearchTerm}`;
      }
      if (startDate && endDate) {
        endpoint = `${endpoint}&startDate=${startDate}&endDate=${endDate}`;
      }
      console.log(endpoint, ' got endpoint')
      const response = await apiCall({ method: "GET", endpoint: endpoint });
      if (response.success) {
        setRows(response.data);
        setTotal(response.total);
      }
    } catch (error) {
      console.error(error);
    } finally{
      setLoading(false);
    }
  }, [page, rowsPerPage, selectedRow?.user_id, debouncedSearchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // handle date click
  const handleDateClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let endpoint = `oem/get-mis-report?page=${page}&limit=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}`;

      const response = await apiCall({ endpoint: endpoint, method: 'GET' });
      if (response.success) {
        setRows(response.data);
        setTotal(response.total);
      }
    } catch (error) {
      console.log(error);
    } finally{
      setLoading(false);
    }
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const handleRowClick = (row) => {
    const userId = row?.user_id;
    navigate(`/oem/mis-report/${userId}`, { state: { rowData: row } });
  };

  const getCurrentDateInIST = () => {
    const now = new Date();

    // Convert to Indian Standard Time (IST)
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long', // 'short' for abbreviated month names
      day: 'numeric',
    };

    return new Intl.DateTimeFormat('en-IN', options).format(now);
  };
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
};

  const downloadExcelDashboard = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("user-cred"));
      const baseUrl = baseURL;
      const endpoint = `dashboard/export-mis-admin?startDate=${startDate}&endDate=${endDate}`;
      const url = `${baseUrl}/${endpoint}`;
  
      const response = await axios.get(url, {
        responseType: 'blob',
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Authorization': `Bearer ${user?.token}`
        }
      });
      console.log('response: ', response);
  
      // Check for empty file
      if (response.data.size === 0) {
        throw new Error('Received empty file');
      }
  
      // Get filename from the response headers
      const contentDisposition = response.headers['content-disposition'];
      let filename = `${formatDate(startDate)}-${formatDate(endDate)}MisReport.xlsx`;
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (filenameMatch && filenameMatch[1]) {
          filename = decodeURIComponent(filenameMatch[1].replace(/['"]/g, ''));
        }
      }
  
      // Create download link with the filename
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = downloadUrl;
      link.download = filename;
  
      // Append to document, click, and cleanup
      document.body.appendChild(link);
      link.click();
  
      setTimeout(() => {
        setSnackBar({
          open: true,
          severity: true,
          message: 'MIS Report downloaded successfully'
        });
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      }, 100);
    } catch (error) {
      return setSnackBar({
        open: true,
        severity: false,
        message: error.message || "An error occurred while downloading the file"
      });
    } finally{
      setLoading(false);
    }
  };

  return (
    <>
      <div className="Template_id_contian">
        <h4 className="Head_title MisReportTitle">
          <div className="DateRangeContain">
            <CustomDateRangePicker rangeKey="mis" /><button type="button" className="SearchDateRange" onClick={handleDateClick}><img src={Search} alt="Search" /></button>
          </div>
          MIS Report
          <div className="Session_report_SearchContain search_btn_in">
            <div>
              <input
                placeholder="Search"
                type="text"
                className=""
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button type="button" className="search_icon_n">
                <SearchIcon />
              </button>
            </div>
            <button type="button" className="ExcelIconContain add_btn7" onClick={downloadExcelDashboard}>
              <img src={excelIcon} alt="excelIcon" className="ExcelIcon" />
            </button>
          </div>

        </h4>
        {/* <h4 className="Head_title AdminSearchContain DailyReport">
          <div className="TodayDeliveryRepot">
          {getCurrentDateInIST()}

          </div>
          Mis Report
          <div className="Session_report_SearchContain">
            <input
              placeholder="Search"
              type="text"
              className=""
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="button">
              <SearchIcon />
            </button>
          </div>
        </h4> */}

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" className="Table DailyReportTable">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => handleRowClick(row)}
                    style={{ cursor: "pointer" }} // Adds a pointer cursor to indicate clickable rows
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return <TableCell key={column.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}
      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};
// Style for the modal
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default OEMMisReport;
